<template>
  <v-card
    class="border-radius-l mt-5"
    :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <v-row class="justify-space-between pt-3 pb-5 px-7">
      <div>
        <h5 class="text-h5 my-2 font-weight-bold text-typo">Auftragsvolumen</h5>
      </div>
    </v-row>
    <v-divider></v-divider>
    <line-chart-volume></line-chart-volume>
  </v-card>
</template>
    
    <script>
import LineChartVolume from "./Charts/LineChartVolume.vue";
export default {
  name: "Customers",
  components: { LineChartVolume },
};
</script>
  