<template>
  <v-card
    class="border-radius-l mt-5"
    :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <v-row class="justify-space-between pt-3 pb-5 px-7">
      <div>
        <h5 class="text-h5 my-2 font-weight-bold text-typo">Übersicht</h5>
      </div>
    </v-row>
    <v-divider></v-divider>
    <v-row class="justify-space-between pt-8 pb-5 px-7 text-center">
      <v-col cols="6" sm="3">
        <div>
          <i class="far fa-user text-warning text-h4"></i>
          <div class="my-1 font-weight-bolder text-lg">
            {{ customers.data ? customers.data.length : 0 }}
          </div>
          <div class="text-s">Kunden</div>
        </div>
      </v-col>
      <v-col cols="6" sm="3">
        <div class="align-space-between">
          <i class="far fa-clipboard text-danger text-h4"></i>
          <div class="my-1 font-weight-bolder text-lg">{{ orderValue }}€</div>
          <div class="text-s">Auftragsvolumen</div>
        </div>
      </v-col>
      <v-col cols="6" sm="3">
        <div>
          <i class="far fa-clipboard text-success text-h4"></i>
          <div class="my-1 font-weight-bolder text-lg">
            {{ projects.data ? projects.data.length : 0 }}
          </div>
          <div class="text-s">
            Projekt{{ projects.data && projects.data.length == 1 ? "" : "e" }}
          </div>
        </div> </v-col
      ><v-col cols="6" sm="3">
        <div>
          <i class="far fa-clipboard text-info text-h4"></i>
          <div class="my-1 font-weight-bolder text-lg">
            {{ tasks }}
          </div>
          <div class="text-s">Aufgabe{{ tasks == 1 ? "" : "n" }}</div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "Overview",
  computed: {
    orderValue() {
      if (this.projects && this.projects.data) {
        let value = 0;
        this.projects.data.forEach((element) => {
          value += element.orderValue;
        });
        return value;
      } else {
        return 0;
      }
    },
    tasks() {
      if (this.projects && this.projects.data) {
        let value = 0;
        this.projects.data.forEach((element) => {
          value += element.tasks.length;
        });
        return value;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style>
</style>