<template>
  <v-row class="pa-0 ma-0 mx-2">
    <v-col cols="12">
      <v-card class="border-radius-lg mt-5">
        <v-row class="justify-space-between pt-3 pb-5 px-7">
          <div>
            <h5 class="text-h5 my-2 font-weight-bold text-typo">Projekte</h5>
          </div>
          <v-btn
            v-if="selectedType == 'provider' && $vuetify.breakpoint.mobile"
            @click="openNewProjectModal()"
            :elevation="0"
            color="#cb0c9f"
            class="font-weight-bolder mt-2 btn-dark bg-light py-4 px-4 mx-2"
            small
          >
            Projekt hinzufügen
          </v-btn>
        </v-row>
        <v-divider></v-divider>
        <v-row
          class="justify-start pb-1 px-4 text-center mt-2"
          v-if="projects.data && projects.data.length"
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
            v-for="project in projects.data"
            :key="project.id"
          >
            <v-card
              class="border-radius-l bg-light text-start w-100"
              :to="'/projects/' + project.id"
            >
              <div class="pa-3">
                <div
                  class="my-1 font-weight-bolder text-lg"
                  style="
                    width: 100%;
                    height: 25px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ project.title }}
                </div>
                <div class="text-s">
                  {{
                    project.tasks.length > 1 || project.tasks.length == 0
                      ? project.tasks.length + " Aufgaben"
                      : project.tasks.length + " Aufgabe"
                  }}
                </div>
              </div>
              <v-card-actions class="bg-gradient-warning">
                <v-row class="align-center">
                  <v-avatar
                    size="50"
                    class="ma-3 text-uppercase text-dark"
                    color="grey"
                  >
                    <v-img
                      :src="getImage(project.imagePath)"
                      :alt="project.serviceProvider.name"
                      v-on:error="onImgError"
                    />
                  </v-avatar>
                  <v-col>
                    <div class="my-1 font-weight-bolder text-md">
                      <span v-if="project.customer">
                        {{ project.customer.name }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <div class="pa-5" v-else>
          <div>Du hast noch keine Projekte, jetzt hinzufügen?</div>

          <v-btn
            @click="openNewProjectModal()"
            :elevation="0"
            color="#cb0c9f"
            class="font-weight-bolder btn-dark bg-light py-4 px-4 mt-5"
            small
          >
            Projekt hinzufügen
          </v-btn>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Overview from "./Widgets/Overview.vue";
import Customers from "./Widgets/Customers.vue";
import OrderVolume from "./Widgets/OrderVolume.vue";
import Projects from "./Widgets/Projects.vue";
import Tasks from "./Widgets/Tasks.vue";

export default {
  name: "Projects",
  components: { Overview, Customers, Projects, OrderVolume, Tasks },
  data() {
    return {};
  },
};
</script>
