<template>
  <v-card class="card-shadow border-radius-xl z-index-2">
    <div class="card-padding pa-5">
      <div class="chart">
        <canvas
          id="line-chart-projects"
          class="chart-canvas"
          height="200"
        ></canvas>
      </div>
    </div>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  name: "line-chart-projects",
  data: function () {
    return {
      lineChartId: "line-chart-projects",
    };
  },
  mounted() {
    new Chart(document.getElementById(this.lineChartId).getContext("2d"), {
      type: "line",
      data: {
        labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: "Direct",
            tension: 0.4,
            pointRadius: 2,
            pointBackgroundColor: "#4CAF50",
            borderColor: "#4CAF50",
            borderWidth: 3,
            backgroundColor: "transparent",
            data: [40, 80, 70, 90, 30, 90, 140, 130, 200],
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            display: false,
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: "#fff",
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#b2b9bf",
              font: {
                size: 14,
                weight: 300,
                family: "Montserrat",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            display: false,
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
              color: "#fff",
            },
            ticks: {
              display: true,
              color: "#b2b9bf",
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Montserrat",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });
  },
};
</script>
